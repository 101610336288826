<template>
	<settings-container window="95%">
		<h2 class="settings-headline">Passwort ändern</h2>
		<form class="settings-form" @submit.prevent="changePassword">
			<label for="current-password">Aktuelles Passwort</label
			><input
				type="password"
				maxlength="256"
				name="current-password"
				data-name="Aktuelles Passwort"
				placeholder=""
				id="current-password"
				autocomplete="password"
				v-model.trim="$v.currentPassword.$model"
			/>
			<span
				class="has-error"
				v-if="$v.currentPassword.$dirty && !$v.currentPassword.required"
				>Das aktuelle Passwort muss gefüllt sein!</span
			>
			<label for="new-password">Neues Passwort</label
			><input
				type="password"
				maxlength="256"
				name="new-password"
				data-name="Neues Passwort"
				placeholder=""
				id="new-password"
				autocomplete="new-password"
				v-model.trim="$v.newPassword.$model"
			/>
			<span
				class="has-error"
				v-if="$v.newPassword.$dirty && !$v.newPassword.required"
				>Das neue Passwort muss gefüllt sein!</span
			>
			<span class="has-error" v-if="!$v.newPassword.minLength"
				>Das neue Passwort muss mindestens 8 Zeichen lang sein!</span
			>
			<span class="has-error" v-if="!$v.newPassword.notSameAsCurrent"
				>Das neue Passwort darf nicht dem alten entsprechen!</span
			>
			<label for="repeat-password">Neues Passwort wiederholen</label
			><input
				type="password"
				maxlength="256"
				name="repeat-password"
				data-name="Neues Passwort wiederholen"
				placeholder=""
				id="repeat-password"
				autocomplete="new-password"
				v-model.trim="$v.repeatPassword.$model"
			/>
			<span
				class="has-error"
				v-if="$v.repeatPassword.$dirty && !$v.repeatPassword.sameAsNewPassword"
				>Die Passwörter stimmen nicht überein!</span
			>
			<button type="submit" class="w-button button">Speichern</button>
			<p class="is-success" v-if="changed && !error">
				Passwort erfolgreich geändert
			</p>
			<p class="has-error" v-if="changed && error">
				Passwort konnte nicht geändert werden. Bitte versuchen Sie es erneut!
			</p>
		</form>
	</settings-container>
</template>

<script>
import { required, sameAs, minLength, not } from 'vuelidate/lib/validators';
import cryptoMixin from '@/mixins/crypto.mixin';
export default {
	components: {
		settingsContainer: () =>
			import('@/components/layout/settings-container.vue')
	},
	mixins: [cryptoMixin],
	data() {
		return {
			currentPassword: '',
			newPassword: '',
			repeatPassword: '',
			loading: false,
			changed: false,
			error: false
		};
	},
	validations: {
		currentPassword: {
			required
		},
		newPassword: {
			required,
			minLength: minLength(8),
			notSameAsCurrent: not(sameAs('currentPassword'))
		},
		repeatPassword: {
			required,
			sameAsNewPassword: sameAs('newPassword')
		}
	},
	methods: {
		async changePassword() {
			this.$v.$touch();
			if (!this.$v.$anyError) {
				this.loading = true;
				let updateData = null;
				const encryptedKey = await this.encryptPrivateKeyCall(this.newPassword);
				updateData = {
					currentPassword: this.currentPassword,
					newPassword: this.newPassword,
					encryptedPrivateKey: encryptedKey
				};
				try {
					const response = await this.$api.post(
						'/auth/change_password',
						updateData,
						{
							headers: {
								Authorization: `Bearer ${this.$store.getters.getUserToken}`
							}
						}
					);
					if (response.status === 200) {
						this.changed = true;
						this.error = false;
						this.clearFields();
					} else {
						this.changed = true;
						this.error = true;
						this.clearFields();
					}
				} catch (e) {
					this.changed = true;
					this.error = true;
					this.clearFields();
				}
			}
		},
		clearFields() {
			this.currentPassword = '';
			this.newPassword = '';
			this.repeatPassword = '';
			this.$v.$reset();
		},
		async encryptPrivateKeyCall(newPassword) {
			const private_key = await this.$store.getters.getPrivateKey;
			const encrypted = await this.encryptPrivateKey(newPassword, private_key);
			return encrypted;
		}
	}
};
</script>

<style lang="scss"></style>
